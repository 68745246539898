<template>
  <div class="page-wrap">
    <div class="top-bar">
      <mtop />
    </div>
    <div class="banner">
      <div class="inner">
        <div class="left">
          <div class="title">政务大数据解决方案</div>
          <div class="desc">对海量的政府服务数据资源进行整理并建立数据模型，进行多角度、多层次的关联分析。为数字政府、城市大脑、互联网+政务服务等多领域建设提供精准全面的大数据量化分析服务，加快政府“用数据决策、数据服务、数据创新”的现代化治理模式的转型。</div>
          <div class="btn" @click="dialogVisible = true, clickMenu('免费试用')">免费试用</div>
        </div>
      </div>
    </div>

    <!-- 政务大数据赋能政府治理的现实困境 -->
    <div class="section bg-white pain">
      <div class="title">政务大数据赋能政府治理的现实困境</div>
      <div class="sub-title">政务大数据的数据来源极其丰富，政务大数据平台的建设和普及应用加速了多元数据的汇聚整合，但各级政府在数字政府建设进程中的政务大数据治理方面仍存在一些问题。</div>
      <div class="content">
        <div class="list">
          <div class="item item-1">
            <div class="title">政府部门间的数据流转存在鸿沟与孤岛</div>
            <div class="desc">数据 “护城河〞"隔离带〞仍然存在数据共享开发技术支撑不足</div>
          </div>
          <div class="item item-2">
            <div class="title">数据格式多样，质量参差不齐、利用率低</div>
            <div class="desc">各级政府、各垂直行业之间缺少统一的 数据标准、存在数据重复、缺失、错误 等问题，质量参差不齐</div>
          </div>
          <div class="item item-3">
            <div class="title">政府部门数据规模庞大，有待挖掘更大的数据价值</div>
            <div class="desc">政府部门业务数据种类众多，且事关百姓生活的方方面面,数据的潜在价值巨大需深入挖掘数据价值，提升政府效能。</div>
          </div>
        </div>
        <div class="bottom-circle">
          现有业务系统多散不成体系，数据价值不明显、业务协同联动难<br/> 缺少政府数据 “大脑〞
        </div>
      </div>
    </div>

    <!-- 建设目标 -->
    <div class="section tar">
      <div class="title">建设目标</div>
      <div class="sub-title">结合当前政务大数据平台建设现状，抓住政务大数据应用的痛点，以云计算、大数据、物联网、区块链、人工智能等技术为支撑，推进政务大数据资源平台建设。为推动政务数字化转型，构建城市大脑，建设智慧城市提供全方面、智能化的数据支撑，助力建立服务高效、治理精准、决策科学的新型政府运行模式，全面提升政府运行 效能。</div>
      <div class="content">
        <div class="bg-tar"></div>
      </div>
    </div>

    <!-- 政务大数据整体介绍 -->
    <div class="section bg-white arc">
      <div class="title">政务大数据整体介绍</div>
      <div class="content">
        <div class="bg-arc"></div>
      </div>
    </div>

    <!-- 政务大数据-总体架构 -->
    <div class="section frame">
      <div class="title">政务大数据-总体架构</div>
      <div class="content">
        <div class="bg-frame"></div>
      </div>
    </div>

    <!-- 案例介绍：合肥市公共资源交易中心大数据监管子系统 -->
<!--    <div class="section bg-white case">-->
<!--      <div class="title">案例介绍：合肥市公共资源交易中心大数据监管子系统</div>-->
<!--      <div class="sub-title">通过数据采集、数据治理和建模分析，搭建监管大数据子系统。数据源涵盖了6.0电子交易系统、6.0评标系统、7.0电子交易系统、7.0评标系统，其中6.0电子交易系统 数据库类型为SqlServer，结构化和非结构化 数据均采用数据库模式存储；7.0电子交易系统数据库类型为MySQL数据库，其中 结构化采取数据库存储、非结构化采取文件服务器存储。</div>-->
<!--      <div class="content">-->
<!--        <div class="bg-case"></div>-->
<!--      </div>-->
<!--    </div>-->

    <!-- 合作伙伴 -->
    <partner/>

    <fotterBanner></fotterBanner>
    <mbottom />

    <demonstrate
        :dialogVisible="dialogVisible"
        @closeDialog="dialogVisible = false"
    ></demonstrate>
  </div>
</template>

<script>
import mtop from "@/components/top.vue";
import Partner from "@/components/partner";
import mbottom from "@/components/bottom.vue";
import fotterBanner from "@/components/fotterBanner.vue";
import demonstrate from "@/components/demonstrate.vue";
import {staClickMenu} from "@/utils";
export default {
  components: {
    Partner,
    mtop,
    mbottom,
    fotterBanner,
    demonstrate
  },
  data() {
    return {
      dialogVisible: false,
      valueList: [
        {
          name: '标准化、规范化管理流程， 减少人员15%以上',
          icon: 'i1.png'
        },
        {
          name: '物联数据联动与分析，降低运 营成本10%以上',
          icon: 'i2.png'
        },
        {
          name: '节能策略，降低能耗10% 以上',
          icon: 'i3.png'
        },
        {
          name: '智慧报警、异常感知，多端联动，快速警报',
          icon: 'i4.png'
        },
        {
          name: '业务场景闭环，协同各子系统 ，深挖场景增值服务',
          icon: 'i5.png'
        },
        {
          name: '形象展示窗口，体现先进管理 理念，提升企业形象',
          icon: 'i6.png'
        },
        {
          name: '管理人员辅助决策， 快速 掌控整体态势',
          icon: 'i7.png'
        },
        {
          name: '数据互联互通，打破信息孤岛， 提升用户体验',
          icon: 'i8.png'
        }
      ],
      planList: [
        {
          name: '数字园区',
          content: `“统一标准、数据集中、应用集成、硬件集群”，对信息资源 进行整合与优化，构建一体化数字园区平台`,
        },
        {
          name: '绿色园区',
          content: `通过软硬件一体化建设，实现能源管控与高效使用，打造绿色 园区文化，实现园区的节能与环保`,
        },
        {
          name: '平安园区',
          content: `人防、技防、物防相结合，构建事前智能感知预警、事中实时 发现与追踪、事后综合分析的管理模式，实现园区安全和谐`,
        },
        {
          name: '感知园区',
          content: `以物联网为基础，基于可视化的应用服务，对园区的各类物联 终端进行集成与监控，实现园区的智能全感知`,
        },
        {
          name: '互联园区',
          content: `基于多源网络融合与移动互联网，将企业服务、员工服务、生 活服务进行无缝链接，实现园区的智慧办公与智慧生活的目标`,
        }
      ]
    }
  },
  methods: {
    getImage(item) {
      return require(`@/assets/community/${item.icon}`)
    },
    clickMenu(menuName, url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != '免费试用') {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
